<template>
  <div>
    <modal name="duedate-modal" height="auto" width="400" :scrollable="true" :draggable="true">
      <div class="row" style="padding: 15px">
        <div class="col-12 mb-2">
          <h4>Due Date</h4>
        </div>
        <el-form :model="form" ref="form" :hide-required-asterisk="true">
          <div class="row" style="padding: 15px">
            <div class="col-12">
              <el-form-item
                  :label="'Due Date'"
                  prop="newDueDate"
                  :rules="[{ required: true, message: 'Due Date is required'}]"
                >
                  <el-date-picker
                    type="date"
                    v-model="form.newDueDate"
                    value-format="yyyy-MM-dd"
                    format="MM/dd/yyyy"
  
                    placeholder="Pick a date"
                    class="w-100"
                  ></el-date-picker>
                </el-form-item> 
            </div>
            <div class="col-12 mb-1"> 
              <el-button type="primary" class="float-right" @click="savedataFormduedate('form')">Change</el-button>
              <el-button type="secondary" class="float-right mr-1" @click="hide_form_duedate()">Cancel</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </modal>
  </div>
</template>

<script>

import documentManager from "@/services/api/documentManager";

export default {
  name: 'modal-due-date',
  data() {
    return {
      form: {
        id:null,
        newDueDate:null
      },
      index:null
    };
  },
  methods: {
    startDuedate(item,index){
      this.form.newDueDate=item.dueDate;
      this.form.id=item.id;
      this.index=index;
      this.show_form_duedate();
    },
    show_form_duedate () {      
      this.$modal.show('duedate-modal');
    },
    hide_form_duedate () {
      this.$modal.hide('duedate-modal');
    },
    savedataFormduedate(formName){
      this.$refs[formName].validate(valid => {
        if (valid) {
          documentManager.editUrl(this.form).then(response => {
            if(response.result.status==="success"){
              this.$emit("refresh", { data: response.result.message, index:this.index});
            }
          });
          this.hide_form_duedate();
        }else{
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
